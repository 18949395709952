import React from 'react';
import { Link } from 'react-router-dom';
import { FcCheckmark } from "react-icons/fc";
import styles from './ProductSection.module.css';
import image1 from '../Media/signup.svg';

function ProductSection() {
    return (
        <div>
            <div>
                <div>
                    <h4 className='text-center my-5 text-light'>Available Services/Products</h4>
                    <div className={styles.productSecMain}>
                        <div>
                            <div className={styles.productPart}>
                                <img src={image1} alt="" />
                                <h5><Link className='text-decoration-none text-warning' to='/product/e_commerce_website'>E commerce webiste with fb pixel set up</Link> </h5>
                                <p>User Friendly <FcCheckmark/></p> 
                                <p>SEO friendly <FcCheckmark/></p>
                                <p>FB pixel and Capi set up <FcCheckmark/></p>
                                <p>কিস্তিতে নেয়ার সুবিধা <FcCheckmark/></p>
                            </div>
                        </div>
                        <div>
                            <div className={styles.productPart}>
                                <img src={image1} alt="" />
                                <h6><Link className='text-decoration-none text-warning' to='/product/pos_sotware'>  POS software for super shop, pharmacy and other business</Link></h6>
                                <p>User Friendly and Easy to use  <FcCheckmark/></p> 
                                <p>Online Base, Backup সুবিধা<FcCheckmark/></p>
                                <p>যেকোনো জায়গায় যেকোনো ডিভাইস দিয়ে  ম্যানেজ করতে পারেবন <FcCheckmark/></p>
                                <p>কিস্তিতে নেয়ার সুবিধা <FcCheckmark/></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductSection;