
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/Home/Home'
import { CartProvider } from './components/CartContext';
import Nav from './components/Nav/Nav';
import NavMobile from './components/NavMobile/NavMobile';
import Footer from './components/Footer/Footer';
import AboutPage from './components/pages/about/AboutPage';
import ContactPage from './components/pages/contact/ContactPage';
import PrivacyPage from './components/pages/privacy-policy/PrivacyPage';
import ReturnPage from './components/pages/return-policy/ReturnPage';
import TermsPage from './components/pages/terms-conditions/TermsPage';
import Error from './components/Error/Error';
import OrderConfrim from './components/Order/OrderConfirm/OrderConfirm';
import SearchPage from './components/Search/SearchPage'
import ProductSection from './components/ProductSection/ProductSection';
import WebsitePage from './components/Products/WebsitePage/WebsitePage';
import PosPage from './components/Products/PosPage/PosPage';
import Testmonials from './components/Testmonials/Testmonials';











// create context



function App() {




  return (

    <CartProvider >
      <Router>
        <Nav />
        <NavMobile />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/search/:searchParams' element={<SearchPage />} />
          <Route path='/pages/about' element={<AboutPage />} />
          <Route path='/pages/contact' element={<ContactPage />} />
          <Route path='/pages/privacy-policy' element={<PrivacyPage />} />
          <Route path='/pages/return-policy' element={<ReturnPage />} />
          <Route path='/pages/terms-conditions' element={<TermsPage />} />
          <Route path='/order-confirm' element={<OrderConfrim />} />
          <Route path='/products' element={<ProductSection />} />
          <Route path='/product/e_commerce_website' element={<WebsitePage />} />
          <Route path='/product/pos_sotware' element={<PosPage />} />
          <Route path='/testmonials/' element={<Testmonials />} />Testmonials
          <Route path='*' element={<Error />} />

        </Routes>
        <Footer />
      </Router>
    </CartProvider>


  );
};

export default App;


