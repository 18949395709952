/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import styles from "./MobileCategory.module.css"




function MobileCategory(props) {
    const { setShowCategory } = props;
    const categories  = []
    const [showMenu, SetShowMeny] = useState('menu')

    const handleMenu = () => {
        SetShowMeny('menu')
    }
    const handleCate = () => {
        SetShowMeny('cate')
    }



    return (
        <div className={styles.mobileCategory}>
            <div>
                <button type='button' onClick={() => setShowCategory(false)} className='btn btn-danger mt-2'><FontAwesomeIcon icon={faClose} /></button>
            </div>
            <Fade>
                <div className='d-flex ms-2'>
                    <button type='button' className='  me-2 btn btn-outline-dark' onClick={handleMenu}>
                        Menu
                    </button>
                    <button type='button' className=' btn btn-outline-dark' onClick={handleCate}>
                        Categries
                    </button>

                </div>

                {
                    showMenu === 'menu' ?
                        <div>
                            <ul>
                                <li onClick={() => setShowCategory(false)}><Link to="/">HOME</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="/products">PRODUCTS</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="/testmonials">TESTMONIALS</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="pages/contact">CONTACT</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="/pages/about">ABOUT</Link></li>
                            </ul>
                        </div> :
                        <div>
                            <ul>
                                {
                                    categories && categories.map(data =>
                                        <Link onClick={() => setShowCategory(false)} key={data.id} to={`category/${data.slug}`}>   <li>  {data.name}</li></Link>
                                    )
                                }
                            </ul>
                        </div>
                }


            </Fade >

        </div >
    );
};

export default MobileCategory;