/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

/* eslint-disable react-hooks/exhaustive-deps */


import { createContext, useState } from 'react';



export const CartContext = createContext();

export function CartProvider({ children }) {
  const initialState = [];
  const [info, setInfo] = useState(initialState);


  return (

    <CartContext.Provider
      value={{  info, setInfo }}
    >
      {children}

    </CartContext.Provider >

  );
}

