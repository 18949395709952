/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaLaptopCode } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { PiCertificate } from "react-icons/pi";
import { faBars, faSearch, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import styles from './NavMobile.module.css';
import MobileCategory from '../MobileCategory/MobileCategory';
// import Logo from '../Logo/bachai.png'

function NavMobile() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [activeMenu, setActiveMenu] = useState('home');
    const [showCategory, setShowCategory] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 250);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMenuClick = (menu) => {
        setActiveMenu(menu);
    };



    const [serachInput, setSearchInput] = useState('')
    const router = useNavigate()
    const handelSearchInput = (e) => {
        setSearchInput(e.target.value)
    }


    const handleSearchOption = () => {
        setShowSearch(!showSearch);
    };
    const handleSearch = () => {
        if (serachInput.length > 0) {
            router(`search/${serachInput}`)
        }
    }



    return (
        <div className={styles.navMobileMain}>
            <div className={`${styles.navMobileTopNotScrolled}`}>
                <div className={styles.navMobileTopMenu}>
                    <div className={styles.logoDiv}>
                        <Link style={{fontFamily: 'monospace', fontSize:'18px'}} className='text-decoration-none text-light' aria-label='go to home page' to='/'>
                            {/* <img className={styles.logo} src={Logo} alt='logo' width={110} /> */}
                            III Soft
                        </Link>
                        {/* <Link className='text-decoration-none text-light' to='/'> <h3 style={{ color: "white" }} className='mt-1'>রূপমহল.কম</h3></Link> */}
                    </div>
                    <div>
                        <FontAwesomeIcon style={{ color: "white" }} onClick={handleSearchOption} icon={showSearch ? faAngleUp : faSearch} />
                    </div>
                </div>
                <div>
                    {showSearch ?
                        <div className={styles.search}>
                            <input type="text" placeholder='Search here...' value={serachInput} onChange={handelSearchInput} />
                            <FontAwesomeIcon className={styles.searchIcon} onClick={handleSearch} icon={faSearch} />
                        </div>
                        : null}
                </div>
            </div>

            {showCategory ? <MobileCategory setShowCategory={setShowCategory} /> : null}
            <div>
                <div className={`${styles.navMobileTop} ${isScrolled ? styles.navMobileTopScrolled : ''}`}>
                    <div className={styles.navMobileTopMenu}>
                        <div className={styles.logoDiv}>
                            <Link style={{fontFamily: 'monospace', fontSize:'18px'}} className='text-decoration-none text-light' aria-label='go to home page' to='/'>
                                III Soft
                                {/* <img className={styles.logo} src={Logo} alt='logo' width={110} /> */}
                            </Link>
                            {/* <Link className='text-decoration-none text-light' to='/'> <h3 style={{ color: "white" }} className='mt-1'>রূপমহল.কম</h3></Link> */}
                        </div>
                        <div>
                            <FontAwesomeIcon onClick={handleSearchOption} icon={showSearch ? faAngleUp : faSearch} />
                        </div>
                    </div>
                    <div>
                        {showSearch ?
                            <div className={styles.search}>
                                <input type="text" placeholder='Search here...' value={serachInput} onChange={handelSearchInput} />
                                <FontAwesomeIcon className={styles.searchIcon} onClick={handleSearch} icon={faSearch} />
                            </div>
                            : null}
                    </div>
                </div>
                <div className={styles.navMobileBar}>
                    <div>
                        <ul>
                            <li onClick={() => handleMenuClick('home')} className={activeMenu === 'home' ? styles.active : ''}>
                                <Link aria-label='go to home page' to="/">
                                    <AiOutlineHome style={{ fontSize: '22px' }} />  <p className={styles.minTitle}>Home</p>
                                </Link>
                            </li>
                            <li onClick={() => setShowCategory(true)} >
                                <FontAwesomeIcon icon={faBars} className={styles.buttonCart} /><p className={styles.minTitle}>Menu</p>
                            </li>
                            <li onClick={() => handleMenuClick('products')} className={activeMenu === 'products' ? styles.active : ''}>
                                <Link aria-label='go to profile page' to='/products'>
                                    <FaLaptopCode style={{ fontSize: '22px' }} /> <p className={styles.minTitle}>Products</p>
                                </Link>
                            </li>
                            <li  onClick={() => handleMenuClick('testmonial')} className={activeMenu === 'testmonial' ? styles.active : ''}>
                                <Link aria-label='go to profile page' to='/'>
                                    <PiCertificate style={{ fontSize: '22px' }} /> 
                                    <p className={styles.minTitle}>Testmonial</p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default NavMobile;