import React from 'react';

import styles from './HeaderSection.module.css'

function HeaderSection() {
  
    return (

        <div className={styles.headerMain}>
            <h2>Welcom to III Soft</h2>
            <p>You will get some special <br /> software and website as your need</p>
        </div>
    );
}

export default HeaderSection;