/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { fetchUrl } from '../../config';

function TermsPage() {
    const [pageData, setPageData] = useState({})
    useEffect(() => {
        window.scrollTo(0,0)
        fetch(`${fetchUrl}/api/terms/`)
            .then(res => res.json())
            .then(data => setPageData(data[0]))
    }, [])

    return (

        <div className='container my-5 p-5'>
            <div className='mt-5'>
                <h2>
                    Terms and Conditions
                </h2>
                <div dangerouslySetInnerHTML={{ __html: pageData.page }} />
            </div>
        </div>

    );
}

export default TermsPage;