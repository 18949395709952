import React , {useLayoutEffect}  from 'react';
import { Helmet } from 'react-helmet';
import HeaderSection from '../HeaderSection/HeaderSection';
import ProductSection from '../ProductSection/ProductSection';

function Home() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Helmet>
                <title>III Soft</title>
                <meta
                    name='description'
                    content='Select your digital product form best selected products. '
                />

            </Helmet>
            <HeaderSection />
            <ProductSection />
        </div>
    );
}

export default Home;