/* eslint-disable react/no-danger */

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FcPhone } from 'react-icons/fc';
import { FiMail } from 'react-icons/fi';

function ContactPage() {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])

    return (
        <div className='container my-5 p-5'>
            <div className='mt-5'>
                <div className='mt-5 text-center'>
                    <p> <a className='text-decoration-none text-light' aria-label='call me' href="tel:+88001862799857" target="_blank" rel="noopener noreferrer"><FcPhone />  Phone - 01862799857</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://api.whatsapp.com/send?phone=+8801862799857&text=Hello'><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp - 01862799857 (ক্লিক করুন)</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://facebook.com/iiisoft'><FontAwesomeIcon icon={faFacebook} /> Facebook (ক্লিক করুন)</a> </p>
                    <p> <a className='text-decoration-none text-light' aria-label='sent mail to me' href="mailto:+aslam1nip@gmail.com" target="_blank" rel="noopener noreferrer"><FiMail /> Email (ক্লিক করুন) </a> </p>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;