/* eslint-disable array-callback-return */
import React, { useEffect, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function OrderConfrim() {
    const route = useNavigate()
    const orderResData = sessionStorage.getItem('orderData')
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 400);
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!orderResData) {
            route('/')
        }
    }, [orderResData, route])

    useEffect(() => {
        if (orderResData) {
            const orderData = JSON.parse(orderResData)
                window.dataLayer = window.dataLayer || []
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: "Purchase",
                        ecommerce: {
                            currency: "BDT",
                            value: orderData && orderData.items.reduce((total, value) => value && total + (value.quantity * value.price), 0),
                            num_items:orderData.items.length,
                            shipping: orderData.delivery_fee,
                            phone: orderData.phone,
                            customer_name: orderData.customer_name,
                            customer_address: orderData.customer_address,
                            country: 'Bangladesh',
                            is_old_customer: orderData.is_old_customer,
                            items: orderData.items
                        }
                    });
                }
        }
       
    }, [orderResData])
  
    return <div>
        <Helmet>
            <title>Successful!! Bachai Mahal</title>
        </Helmet>
        <div className='text-center  mt-5 pt-5'>
            <div className='mt-5 '>
                <h1 className='text-success '> Order Place Successful !</h1>
                <p className='text-success '>আপনার অর্ডারটি সফলভাবে সম্পন্ন হয়েছে, <br />
                    আমাদের কল সেন্টার থেকে একজন প্রতিনিধি কল দিয়ে <br />
                    আপনার অর্ডারটি কনফার্ম করবে।</p>
                <Link className='' to='/shop'> <button type='button' className=' btn btn-success'>আরো প্রোডাক্ট বাছাই করুন</button></Link>

            </div>
        </div>
    </div>
}

export default OrderConfrim;