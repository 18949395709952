import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styles from './CarouselImages.module.css'

function CarouselImages(props) {
    const { carouselList } = props
    return (
        <div className={styles.sliderMain}>
            {/* <Carousel fade>
                {
                    carouselList.map(data =>
                        <Carousel.Item>
                            <img width='100%' alt='imagesas' src={data} />
                            <Carousel.Caption>
                                <div className={styles.captionSec}>
                                    <h3>First slide label</h3>
                                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                }
            </Carousel> */}
            <Carousel slide={false}>
                {
                    carouselList.map(data =>
                        <Carousel.Item>
                            <img width='100%' alt='imagesas' src={data.image} text="First slide" />
                            <Carousel.Caption>
                                <div className={styles.captionSec}>
                                    <h3>{data.title}</h3>
                                    <p>{data.des}</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                }
            </Carousel>

        </div>
    );
}

export default CarouselImages;


