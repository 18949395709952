/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

function AboutPage() {
    const [pageData, setPageData] = useState({})


    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    return (
        <div className='container my-5 p-5'>
            <div className='mt-5'>
                <div className='mt-5 text-center'>
                    <p> <a className='text-decoration-none text-light' aria-label='call me' href="tel:+88001862799857" target="_blank" rel="noopener noreferrer">  Phone - 01862799857</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://api.whatsapp.com/send?phone=+8801862799857&text=Hello'> Whatsapp - 01862799857 (ক্লিক করুন)</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://facebook.com/iiisoft'> Facebook (ক্লিক করুন)</a> </p>
                    <p> <a className='text-decoration-none text-light' aria-label='sent mail to me' href="mailto:+aslam1nip@gmail.com" target="_blank" rel="noopener noreferrer"> Email (ক্লিক করুন) </a> </p>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;