import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function SearchPage() {
  const { searchParams } = useParams()

  return (
    <div className='mtop'>

      <Helmet>
        <title>{searchParams || 'Bachai Mahal'}</title>
        <meta
          name='description'
          content='Search products List. '
        />
      </Helmet>
      <div>
        <p>This is search page</p>
      </div>
    </div>
  );
};

export default SearchPage;