import React from 'react';

function Testmonials() {
    return (
        <div className='pt-5'>
            <p className='mt-5 pt-5 text-capitalize text-center  text-light'>
                This part is under development
            </p>
        </div>
    );
}

export default Testmonials;